import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackButton() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <IconButton
            onClick={handleBack}
            style={{
                position: 'fixed',
                top: '16px',
                left: '16px',
                borderRadius: '50%',
            }}
            aria-label="back"
        >
            <ArrowBackIcon />
        </IconButton>
    );
}