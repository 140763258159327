import {useParams, Link} from "react-router-dom";
import {useState} from "react";

import LoadingSpinner from "../../Components/LoadingSpinner";
import {useRequestContext} from "../../Contexts/Request";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import {CardActions} from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PlaceIcon from '@mui/icons-material/Place';
import LinkIcon from '@mui/icons-material/Link';



function FormatedDistance({ distance }) {
    distance = (distance < 1000 ? Math.round(distance / 100) * 100 + 'm' : (distance / 1000).toFixed(1) + 'km');
    return (
        <Typography variant="body2" color="textSecondary">
            <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 0.5 }} />
            {distance}
        </Typography>
    )
}

function AccomodationOptionListItem({ accommodationOption }){
    const { sendRequest } = useRequestContext()
    const [address, setAddress] = useState(null);

    if (address === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Addresses/' + accommodationOption.address,
            setter: setAddress
        })
        return <LoadingSpinner/>
    }

    return (
        <Card sx={{ padding: '1em', margin: '1em' }}>
            <CardContent>
                <Typography variant="h6" component="div">
                    {accommodationOption.name}
                </Typography>
                <Grid container spacing={1} columns={6}>
                    <Grid item xs={1}>

                        <Typography variant="body2" color="textSecondary">
                            <PeopleIcon sx={{ verticalAlign: 'middle', mr: 0.5 }} />
                            {accommodationOption.places} places
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <FormatedDistance distance={accommodationOption.distanceFromEvent}/>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Link to={ accommodationOption.url }>
                    <Button>
                        <LinkIcon />
                    </Button>
                </Link>

                <Link to={ 'https://www.google.fr/maps/place/'+address.number+'+'+address.street+'+'+address.city} >
                    <Button>
                        <PlaceIcon />
                    </Button>
                </Link>
            </CardActions>
        </Card>
    );
}

function UserEventAccommodationsOptionsForm({ id }){
    const { sendRequest } = useRequestContext()
    const [accommodationsOptions, setAccommodationsOptions] = useState(null);

    if (accommodationsOptions === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/AccommodationsOptions/Events/' + id,
            setter: setAccommodationsOptions
        })
        return <LoadingSpinner/>
    }

    return(
        <>{
            accommodationsOptions.map( (accommodationOption) =>
                <AccomodationOptionListItem key={ accommodationOption.id } accommodationOption={accommodationOption} />
            )
        }</>
    );
}

export default function UserEventAccomodationOptionPage() {
    const { id }  = useParams();

    return (
        <div className="event page">
            <UserEventAccommodationsOptionsForm id={ id } />
        </div>
    );
}