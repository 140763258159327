import { useState } from "react";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from "react";
import LoadingSpinner from "../LoadingSpinner";

export default function CityInput({ address, setAddress })
{
    const [ cities, setCities ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const updateMatchedCities = (e) => {
        setAddress({...address, city: e.target.value})
        if (address && address.city  && e.target.value.length > 2) {
            setIsLoading(true)
            fetch('https://geo.api.gouv.fr/communes?boost=population&limit=3&nom=' + e.target.value)
                .then((response) => response.json())
                .then((responseData) => {
                    setIsLoading(false)
                    setCities(responseData)

                    if (e.target.value.length > address.city.length && responseData.length === 1) {
                        let city = responseData[0].nom
                        setAddress({...address, city: city, postcode: responseData[0].codesPostaux[0] })
                    }
                })
        }
    }

    const updatePostcode = (city) => {
        fetch('https://geo.api.gouv.fr/communes?boost=population&limit=3&nom=' + city)
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.length > 0
                    && responseData[0].codesPostaux[0].length > 0
                    && !responseData[0].codesPostaux.includes(address.postcode)
                ){
                    setAddress({ ...address, postcode: responseData[0].codesPostaux[0]})
                }
            })
    }
    return (
        <Autocomplete
            options={ cities }
            autoHighlight
            inputValue={ (address && address.city) || ""  }
            getOptionLabel={(option) => option.nom }
            onChange={ (e) => updatePostcode(e.target.value) }
            required
            isOptionEqualToValue={(option, value) => value === undefined || value === "" || option.id === value.id}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        name="city"
                        onChange={ updateMatchedCities }
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {isLoading ? <LoadingSpinner/>  : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )
            } }
            renderOption={(props, option, { selected }) => (
                <option {...props} key={option.code}>{option.nom}</option>
            )}
        />
    );
}