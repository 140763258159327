import {useParams} from "react-router-dom";
import {useState} from "react";

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import {useRequestContext} from "../../Contexts/Request";
import PostCodeInput from "../../Components/Inputs/PostCode";
import CityInput from "../../Components/Inputs/City";
import StreetInput from "../../Components/Inputs/Street";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {useNotificationsContext} from "../../Contexts/Notifications";
import LoadingSpinner from "../../Components/LoadingSpinner";

function AccommodationOptionForm({ id }) {
    const {sendRequest} = useRequestContext();
    const { addNotification } = useNotificationsContext()
    const [accommodationOption, setAccommodationOption] = useState(null);
    const [address, setAddress] = useState(null);

    if (accommodationOption === null) {
        sendRequest({
            endpoint: '/AccommodationsOptions/' + id,
            method: 'GET',
            setter: setAccommodationOption
        })
        return <LoadingSpinner/>
    }


    if (address === null) {
        sendRequest({
            endpoint: '/Addresses/' + accommodationOption.address,
            method: 'GET',
            setter: setAddress
        })
        return <LoadingSpinner/>
    }

    const sendUpdateAccommodationOptionRequest = (e) => {
        e.preventDefault();
        sendRequest({
            endpoint: '/Addresses/' + accommodationOption.address,
            method: 'PUT',
            data: {
                postcode: e.target.elements.postcode.value,
                city: e.target.elements.city.value,
                number: e.target.elements.number.value,
                street: e.target.elements.street.value,
            },
            onSuccess: (addressData) => {
                sendRequest({
                    endpoint: '/AccommodationsOptions/' + id,
                    method: 'PUT',
                    data: {
                        name: e.target.elements.name.value,
                        url: e.target.elements.url.value,
                        places: e.target.elements.places.value ?? null,
                    },
                    onSuccess: () => {
                        addNotification('success', 'Hébergement mis à jour')
                    }
                });
            }
        });
    }
    return (
        <form onSubmit={ sendUpdateAccommodationOptionRequest }>
            <Grid container spacing={1} columns={6}>
                <Grid item xs={6}>
                    <InputLabel htmlFor="name">Titre</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="name"
                        required
                        defaultValue={accommodationOption.name}
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="postcode">Code postal</InputLabel>
                    <PostCodeInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="city">Ville</InputLabel>
                    <CityInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="number">N° de voie</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="number"
                        required
                        defaultValue={address.number}
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="street">Voie</InputLabel>
                    <StreetInput address={address} setAddress={setAddress}/>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <InputLabel htmlFor="url">Site</InputLabel>
                <OutlinedInput
                    type="url"
                    fullWidth
                    name="url"
                    defaultValue={accommodationOption.url}
                />
            </Grid>
            <Grid item xs={2}>
                <InputLabel htmlFor="places">Nombre de places</InputLabel>
                <OutlinedInput
                    type="number"
                    fullWidth
                    name="places"
                    defaultValue={accommodationOption.places}
                />
            </Grid>
            <Divider sx={{margin: '1em'}} variant="middle"/>
            <Button fullWidth type="submit" variant="contained">Mettre à jour</Button>
        </form>
    );
}

export default function AccomodationOptionPage() {
    const { id }  = useParams();

    return (
        <div className="event page">
            <AccommodationOptionForm id={ id } />
        </div>
    );
}

