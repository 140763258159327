import { Link } from "react-router-dom";
import { useNavigate} from "react-router-dom";

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';

import { useUserContext } from "../../Contexts/User";
import { useRequestContext } from "../../Contexts/Request";
import CentredContent from "../../Components/CentredContent";

function LoginForm(){
    const navigate = useNavigate();
    const { sendRequest } = useRequestContext()
    const { setUser } = useUserContext()

    const loginRequest = (e) => {
        e.preventDefault();
        sendRequest({
            endpoint: "/Users/login",
            method: 'POST',
            data: {
                email: e.target.elements.email.value,
                password: e.target.elements.password.value
            },
            setter: setUser,
            onSuccess: () => {
                navigate('/events');
            }
        })
    }


    return(
        <form onSubmit={ loginRequest }>
            <InputLabel htmlFor="email">Adresse mail</InputLabel>
            <OutlinedInput
                type="email"
                name="email"
                fullWidth
                required
                autoComplete="username"
                startAdornment={ <InputAdornment position="start"><EmailIcon /></InputAdornment> }
            />
            <InputLabel htmlFor="password" autoComplete="true">Mot de passe</InputLabel>
            <OutlinedInput
                type="password"
                name="password"
                fullWidth
                required
                autoComplete="current-password"
                startAdornment={ <InputAdornment position="start"><KeyIcon /></InputAdornment> }
            />
            <Divider sx={{margin: '0.5em'}} variant="middle" />
            <Button fullWidth type="submit" variant="contained">Connection</Button>
        </form>
    )
}

export default function LoginPage() {
    return (
        <div className="login page">
            <h1>Login</h1>
            <Divider sx={{margin: '1em'}} variant="middle" />
            <LoginForm/>
            <Divider sx={{margin: '0.5em'}} variant="middle" />
            <CentredContent>
                <Link to="/password-reset">Mot de passe oublié</Link>
            </CentredContent>
        </div>
    );
}