import * as React from 'react';
import {Link} from "react-router-dom"

import AppBar from '@mui/material/AppBar';
import Grid from "@mui/material/Grid";
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import CentredContent from "./CentredContent";



export default function Navbar() {
    return(
      <AppBar position="fixed" color="primary" sx={ { top: 'auto', bottom: 0, padding: '0.5em'} }>
          <Grid container spacing={1} columns={2}>
              <Grid item xs={1}>
                  <CentredContent>
                        <Link to="/events">
                            <CalendarMonthIcon fontSize="large" />
                        </Link>
                  </CentredContent>
              </Grid>
              <Grid item xs={1} >
                  <CentredContent>
                    <Link to="/profil">
                        <SettingsIcon fontSize="large" />
                    </Link>
                  </CentredContent>
              </Grid>
          </Grid>
      </AppBar>
    )
}

