
import {useParams} from "react-router-dom";
import {useState} from "react";

import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import {useRequestContext} from "../../Contexts/Request";
import { useUserContext } from "../../Contexts/User";
import {useNotificationsContext} from "../../Contexts/Notifications";
import LoadingSpinner from "../../Components/LoadingSpinner";

function NewHouseholdForm({ id })
{
    const { sendRequest } = useRequestContext();
    const { addNotification } = useNotificationsContext();
    const { user } = useUserContext();
    const [event, setEvent] = useState(null);


    if (event ===  null){
        sendRequest({
            endpoint: '/Events/' + id,
            method: 'GET',
            setter: setEvent,
        });
        return <LoadingSpinner />
    }

    if (event.owner !==  user.id) {
        return <LoadingSpinner />
    }


    const sendInsertHouseholdRequests = (e) => {
        e.preventDefault();
        sendRequest({
            endpoint: '/Households/',
            method: 'POST',
            data: {
                name: e.target.elements.name.value,
                event: event.id
            },
            onSuccess: (addressData) => {
                addNotification('success', "Le foyer a bien été crée.");
            }
        });
    }

    return(
        <form onSubmit={ sendInsertHouseholdRequests }>
            <InputLabel htmlFor="name">Nom du Foyer</InputLabel>
            <OutlinedInput
                type="text"
                fullWidth
                name="name"
                required
            />
            <Divider sx={{margin: '1em'}} variant="middle" />
            <Button fullWidth type="submit" variant="contained">Mettre à jour</Button>
        </form>
    );
}

export default function NewHouseholdPage() {
    const { id }  = useParams();

    return (
        <div className="event page">
            <NewHouseholdForm id={ id } />
        </div>
    );
}
