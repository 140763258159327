import React from 'react';
import { createContext, useState, useContext } from "react";

export const NotificationsContext = createContext({
    notifications: [],
    addNotification: (type, title, content) => {},
    closeNotification: (id) => {},
    closeAllNotification: () => {}
});

export const NotificationsContextProvider = ({ children }) => {
    const [ notifications, setNotifications ] = useState([])

    const addNotification = (type, content) => {
        let alreadyExist = false;
        for (const notification of notifications)
            if (notification.type === type && notification.content === content)
                alreadyExist = true

        if (!alreadyExist){
            const currentDate = new Date();
            setNotifications([
                ...notifications,
                {
                    timestamp: currentDate.getTime(),
                    type: type,
                    content: content,
                }
            ])
        }
    }

    const closeNotification = (index) => {
        notifications.splice(index, 1)
        setNotifications([...notifications])
    }

    const closeAllNotification = () => {
        setNotifications([])
    }

    const context = {
        notifications,
        addNotification,
        closeNotification,
        closeAllNotification
    }

    return (
        <NotificationsContext.Provider value={context}>{ children }</NotificationsContext.Provider>
    )
}

export function useNotificationsContext(){
    return useContext(NotificationsContext)
}