import Box from "@mui/material/Box";


export default function CentredContent({ children }){
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {children}
        </Box>
    );
}