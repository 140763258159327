import {useRequestContext} from "../../Contexts/Request";
import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import LoadingSpinner from "../../Components/LoadingSpinner";
import InputLabel from "@mui/material/InputLabel";
import {FormControl, MenuItem, Select} from "@mui/material";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";


function SelectHouseholdUser({ householdToken }) {
    const {sendRequest} = useRequestContext()
    const navigate = useNavigate();
    const [users, setUsers] = useState(null)

    if (householdToken && householdToken.length && users == null) {
        sendRequest({
            endpoint: '/Households/' + householdToken + '/Users',
            method: 'GET',
            setter: setUsers
        });
        return <LoadingSpinner/>
    }

    const RedirectToUserRegisterPage = (e) => {
        navigate('/register/' + e.target.elements.user.value,)
    }

    return (
        <form onSubmit={ RedirectToUserRegisterPage }>
            <FormControl fullWidth>
                <InputLabel id="label-users">Qui êtes-vous</InputLabel>
                <Select
                    labelId="label-users"
                    id="users"
                    label="Qui êtes-vous"
                    name="user"
                >
                    <MenuItem key={0} value={0}> - </MenuItem>
                    {users.map((user) =>
                        <MenuItem key={user.id} value={user.token}>
                            {user.firstName} {user.lastName}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <Divider sx={{margin: '1em'}} variant="middle"/>
            <Button fullWidth type="submit" variant="contained">Valider</Button>
        </form>
    );
}


export default function HouseholdRegisterPage() {
    const { householdToken }  = useParams();

    return (
        <div className="event page">
            <SelectHouseholdUser householdToken={ householdToken } />
        </div>
    );
}
