import * as React from 'react';
import { useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import LoadingSpinner from "../LoadingSpinner"


export default function StreetInput({ address, setAddress })
{
    const [ streets, setStreets ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const updateMatchedStreet = (e) => {
        setAddress({...address, street: e.target.value})

        if (address && address.street && e.target.value.length > 3) {
            setIsLoading(true)
            fetch('https://api-adresse.data.gouv.fr/search/?limit=5&type=street&postcode=' + address.postcode + '&q=' + address.number + '+' + e.target.value)
                .then((response) => response.json())
                .then((geoJson) => {
                    setIsLoading(false)
                    setStreets(geoJson.features.map((feature) => feature.properties))
                    if (e.target.value.length > address.street.length && geoJson.features.length === 1) {
                        setAddress({...address, street: geoJson.features[0].properties.name})
                    }
                })
        }
    }

    return (
        <Autocomplete
            options={ streets }
            autoHighlight
            inputValue={ (address && address.street) || ""  }
            //defaultValue={ {name: address.street} }
            getOptionLabel={(option) => option.name || address.street }
            required
            isOptionEqualToValue={(option, value) => value === undefined || value === "" || option.id === value.id}
            onChange={ updateMatchedStreet }
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        name="street"
                        onChange={ updateMatchedStreet }
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {isLoading ? <LoadingSpinner/>  : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )
            } }
            renderOption={(props, option, { selected }) =>
                <option {...props} key={option.id}>{option.name}</option>
            }
        />
    );
}