const errorCodes = {
    invalidApiToken: {
        msg: "Vous avez été déconnecté.",
        redirect: "login",
        callback: (user , setUser) => {
           setUser(null);
        }
    },
    notRequestedDataExist : {
        msg: "La ressource demandée n'existe pas.",
        redirect: "events"
    },
    notUserEmailValidated : {
        msg: "Vous n'avez pas encore validé votre adresse mail."
    },
    invalidUserToken:       {
        msg:"Impossible de valider votre adresse mail."
    },
    invalidUserPassword:    {
        msg:"Identifiant ou mot de passe incorecte."
    },
    userAlreadyExist:       {
        msg:"Un utilisateur existe déja avec cet email ou ce numéro de téléphone."
    },
    notUserEmailExist:{
        msg: "Cet adresse mail n'est associé à aucun compte."
    }
};
export default errorCodes;