import { useState } from 'react';
import { useParams } from "react-router-dom";
import {Link} from "react-router-dom";
import dayjs from 'dayjs';
import React from 'react';

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PlaceIcon from '@mui/icons-material/Place';
import GroupIcon from '@mui/icons-material/Group';
import HotelIcon from '@mui/icons-material/Hotel';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Fab from "@mui/material/Fab";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


import CentredContent from "../../Components/CentredContent";
import PostCodeInput from "../../Components/Inputs/PostCode";
import CityInput from "../../Components/Inputs/City";
import StreetInput from "../../Components/Inputs/Street";
import LoadingSpinner from "../../Components/LoadingSpinner";
import {useRequestContext} from "../../Contexts/Request";
import {useNotificationsContext} from "../../Contexts/Notifications";


function InvitationsListElem({ invitation, deleteInvitationRequest }) {
    const { sendRequest } = useRequestContext()
    const [user, setUser] = useState(null)

    if (user === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Users/' + invitation.user,
            setter: setUser
        })
        return <LoadingSpinner/>
    }

    return (
        <Card sx={{ padding: '1em', margin: '5px' }} >
            <Grid container spacing={1} columns={12}>
                <Grid item xs={1} >
                    { invitation.status === 'yes' && <CheckIcon sx={ {color: 'green' }} /> }
                    { invitation.status === 'maybe' && <QuestionMarkIcon sx={ {color: 'orange' }}/> }
                    { invitation.status === 'no' && <CloseIcon sx={ {color: 'red' }} /> }
                </Grid>
                <Grid item xs={9} >
                    {user.firstName + ' ' + user.lastName}
                </Grid>
                <Grid item xs={1} >
                    <Link to={"/invitation/" + invitation.id}>
                        <Fab color="primary" aria-label="edit" size="small">
                            <EditIcon  />
                        </Fab>
                    </Link>
                </Grid>
                <Grid item xs={1} >
                    <Fab color="error" aria-label="delete" size="small">
                        <DeleteIcon onClick={ () => deleteInvitationRequest(invitation.id) }/>
                    </Fab>
                </Grid>
            </Grid>
        </Card>
    )
}

function HouseholdListItem({ household, deleteHouseholdRequest }) {
    const { sendRequest } = useRequestContext()
    const { addNotification } = useNotificationsContext()
    const [invitations, setInvitations] = useState(null);

    const deleteInvitationRequest = (invitationId) => {
        sendRequest({
            method: 'DELETE',
            endpoint: '/Invitations/' + invitationId,
            onSuccess: () => {
                setInvitations(invitations.filter( (invitation) => invitation.id !== invitationId ));
                addNotification('success', 'Invitation supprimé');
            },
        })
    }

    if (invitations === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Households/' + household.id + '/Invitations',
            setter: setInvitations
        });

        return <LoadingSpinner/>
    }

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                {household.name}
            </AccordionSummary>
            <AccordionDetails>
                {
                    invitations.map((invitation) =>
                        <InvitationsListElem
                            key={invitation.id}
                            invitation={invitation}
                            deleteInvitationRequest={deleteInvitationRequest}
                        />
                    )
                }
            </AccordionDetails>
            <AccordionActions>
                <Fab color="primary" aria-label="add" size="small">
                    <Link to={"/invitation/new/" + household.id}>
                        <PersonAdd />
                    </Link>
                </Fab>
                <Fab color="error" aria-label="delete" size="small">
                    <DeleteIcon onClick={ () => deleteHouseholdRequest(household.id) }/>
                </Fab>
            </AccordionActions>
        </Accordion>
    );
}
function EventHouseholdsList({ event }) {
    const { sendRequest } = useRequestContext()
    const { addNotification } = useNotificationsContext()
    const [households, setHouseholds] = useState(null);

    // fetch invitation and stats
    if (households === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Households/Event/' + event.id,
            setter: setHouseholds
        });

        return <LoadingSpinner/>
    }
    const deleteHouseholdRequest = (householdId) => {
        sendRequest({
            method: 'DELETE',
            endpoint: '/Households/' + householdId,
            onSuccess: () => {
                setHouseholds(households.filter( (household) => household.id !== householdId ));
                addNotification('success', 'Foyer supprimé');
            },
        })
    }

    return (
        <>
            { households.map( (household) =>
                <HouseholdListItem
                    key={household.id}
                    household={household}
                    deleteHouseholdRequest={deleteHouseholdRequest}
                />
            ) }
            <Fab color="primary" aria-label="add" style={ { position: 'fixed',bottom: '5em',right: '1em' } }>
                <Link to={"/household/new/" + event.id}>
                    <GroupAddIcon />
                </Link>
            </Fab>
        </>
    );
}

function AccommodationOptionListElem({ accommodationOption , deleteAccommodationOptionRequest}){
    return (
        <Grid container spacing={1} columns={12}>
            <Grid item xs={10} >
                <strong>{ accommodationOption.name }</strong>
            </Grid>
            <Grid item xs={1} >
                <Link to={ "/accommodationOption/" + accommodationOption.id }>
                    <Fab color="primary" aria-label="edit" size="small">
                        <EditIcon />
                    </Fab>
                </Link>
            </Grid>
            <Grid item xs={1} >
                <Fab color="error" aria-label="delete" size="small">
                    <DeleteIcon onClick={ () => deleteAccommodationOptionRequest(accommodationOption.id) }/>
                </Fab>
            </Grid>
        </Grid>
    );
}
function EventAccommodationsOptionsList({ event }) {
    const { sendRequest } = useRequestContext()
    const { addNotification } = useNotificationsContext()
    const [accommodationsOptions, setAccommodationsOptions] = useState(null);


    if (accommodationsOptions === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/AccommodationsOptions/Events/' + event.id,
            setter: setAccommodationsOptions
        })
        return <LoadingSpinner/>
    }

    const deleteAccommodationOptionRequest = (id) => {
        sendRequest({
            method: 'DELETE',
            endpoint: '/AccommodationsOptions/' + id,
            onSuccess: () => {
                setAccommodationsOptions(accommodationsOptions.filter((accommodationOption) => accommodationOption.id !== id));
                addNotification('success', "L'option d'hébergement a bien été supprimer")
            },
        })
    }

    return (
        <>
            { accommodationsOptions.map( (accommodationOption) =>
                <Card key={ accommodationOption.id } sx={{ padding: '1em', margin: '1em' } }>
                    <AccommodationOptionListElem
                        accommodationOption={ accommodationOption }
                        deleteAccommodationOptionRequest={ deleteAccommodationOptionRequest }
                    />
                </Card>
            ) }
            <Fab color="primary" aria-label="add" style={ { position: 'fixed',bottom: '5em',right: '1em' } }>
                <Link to={"/accommodationOption/new/" + event.id}>
                    <AddLocationIcon />
                </Link>
            </Fab>
        </>
    );
}

function EventForm({ event, setEvent }){
    const { sendRequest } = useRequestContext()
    const { addNotification } = useNotificationsContext()
    const [address, setAddress] = useState(null);

    if (address === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Addresses/' + event.address,
            setter: setAddress
        })
        return <LoadingSpinner/>
    }

    const sendUpdateEventRequests = (e) => {
        e.preventDefault();
        sendRequest({
            endpoint: '/Events/' + event.id,
            method: 'PUT',
            data: {
                name: e.target.elements.name.value,
            },
            setter: setEvent,
            onSuccess: () => {
                sendRequest({
                    method: 'PUT',
                    endpoint: '/Addresses/' + event.address,
                    data: {
                        postcode: e.target.elements.postcode.value,
                        city: e.target.elements.city.value,
                        number: e.target.elements.number.value,
                        street: e.target.elements.street.value,
                    },
                    setter: setAddress,
                    onSuccess: () => {
                        addNotification('success', "L'évenement a bien été mis à jour");
                    }
                })
            }
        });
    }

    return(
        <form onSubmit={ sendUpdateEventRequests }>
            <Grid container spacing={1} columns={6}>
                <Grid item xs={3} >
                    <CentredContent>
                        <DateTimePicker
                            label="Du"
                            defaultValue={ dayjs(event.dateStart) }
                            fullWidth
                            required
                        />
                    </CentredContent>
                </Grid>
                <Grid item xs={3}>
                    <CentredContent>
                        <DateTimePicker
                            label="Au"
                            defaultValue={ dayjs(event.dateEnd) }
                            fullWidth
                            required
                        />
                    </CentredContent>
                </Grid>
                <Grid item xs={6} >
                    <InputLabel htmlFor="name">Titre</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="name"
                        defaultValue={ event.name }
                        required
                    />
                    <Divider sx={{margin: '1em'}} variant="middle" />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="postcode">Code postal</InputLabel>
                    <PostCodeInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="city">Ville</InputLabel>
                    <CityInput address={address} setAddress={setAddress}/>
                </Grid>

                <Grid item xs={2}>
                    <InputLabel htmlFor="number">N° de voie</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="number"
                        defaultValue={ address.number }
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="street">Voie</InputLabel>
                    <StreetInput address={address} setAddress={setAddress}/>
                </Grid>
            </Grid>
            <Divider sx={{margin: '1em'}} variant="middle" />
            <Button fullWidth type="submit" variant="contained">Mettre à jour</Button>
        </form>
    );
}

function EventStats({ stats }) {
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item>
                <Card sx={{ minWidth: 150 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Invitations
                        </Typography>
                        <Typography variant="body2">
                            Total: {stats.invitations}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card sx={{ minWidth: 150 }}>
                    <CardContent>
                        <Typography variant="h5" component="div" color="green">
                            Yes
                        </Typography>
                        <Typography variant="body2">
                            {stats.yes}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card sx={{ minWidth: 150 }}>
                    <CardContent>
                        <Typography variant="h5" component="div" color="orange">
                            Maybe
                        </Typography>
                        <Typography variant="body2">
                            {stats.maybe}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card sx={{ minWidth: 150 }}>
                    <CardContent>
                        <Typography variant="h5" component="div" color="red">
                            No
                        </Typography>
                        <Typography variant="body2">
                            {stats.no}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default function EventPage() {
    const { id }  = useParams();
    const { sendRequest } = useRequestContext();
    const [event, setEvent] = useState(null);
    const [stats, setStats] = useState(null);
    const [currentTab, setCurrentTab] = useState('invitations');

    // fetch Event and AccomodationsOptions at begin
    if (event === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Events/' + id,
            setter: setEvent
        })
        return <LoadingSpinner/>
    }

    if (stats === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Invitations/Stats/Event/' + event.id,
            setter: setStats
        });

        return <LoadingSpinner/>
    }

    // ADD STAST HERE

    return (
        <div className="event page">
            <EventStats stats={stats} />
            <Box sx={{ width: '100%' , marginBottom: '33px'}}>
                <Tabs value={currentTab} onChange={(e, value) => {setCurrentTab(value)}}>
                    <Tab value="invitations" label={ <GroupIcon /> } />
                    <Tab value="accommodationsOptions" label={ <HotelIcon /> } />
                    <Tab value="event" label={ <PlaceIcon /> } />
                </Tabs>
            </Box>
            { currentTab === "invitations" && <EventHouseholdsList event={ event } /> }
            { currentTab === "accommodationsOptions" && <EventAccommodationsOptionsList event={ event } />}
            { currentTab === "event" && <EventForm event={ event } setEvent={setEvent}/> }
        </div>
    );
}
