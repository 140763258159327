
import {useParams} from "react-router-dom";
import {useState} from "react";

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import {useRequestContext} from "../../Contexts/Request";
import {useNotificationsContext} from "../../Contexts/Notifications";
import PostCodeInput from "../../Components/Inputs/PostCode";
import CityInput from "../../Components/Inputs/City";
import StreetInput from "../../Components/Inputs/Street";
import CentredContent from "../../Components/CentredContent";
import LoadingSpinner from "../../Components/LoadingSpinner";

function NewInvitationForm({ id })
{
    const { sendRequest } = useRequestContext();
    const { addNotification } = useNotificationsContext();
    const [address, setAddress] = useState(null);
    const [household, setHousehold] = useState(null)

    if (household ===  null){
        sendRequest({
            endpoint: '/Households/' + id,
            method: 'GET',
            setter: setHousehold,
        });
        return <LoadingSpinner />
    }

    const sendInsertInvitationRequests = (e) => {
        e.preventDefault();
        sendRequest({
            endpoint: '/Addresses/',
            method: 'POST',
            data: {
                postcode: e.target.elements.postcode.value,
                city: e.target.elements.city.value,
                number: e.target.elements.number.value,
                street: e.target.elements.street.value,
            },
            onSuccess: (addressData) => {
                sendRequest({
                    endpoint: '/Users/',
                    method: 'POST',
                    data: {
                        firstName: e.target.elements.firstName.value,
                        lastName: e.target.elements.lastName.value,
                        phone: e.target.elements.phone.value,
                        email: e.target.elements.email.value,
                        password: Math.random().toString(36).slice(2, 7),
                        address: addressData.id,
                        household: household.id
                    },
                    onSuccess: (userData) => {
                        sendRequest({
                            endpoint: '/Invitations/',
                            method: 'POST',
                            data: {
                                user: userData.id,
                                event: household.event,
                                status: e.target.elements.status.value,
                            },
                            onSuccess: () => {
                                addNotification(
                                    'success',
                                    "L'utilisateur a bien été crée."
                                )
                            }
                        });
                    }
                });
            }
        });
    }

    return(
        <form onSubmit={ sendInsertInvitationRequests }>
            <Grid item xs={6}>
                <CentredContent>
                    <FormControl sx={{ display: 'in-line' }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="yes"
                            name="status"
                        >
                            <FormControlLabel
                                label="Présent"
                                value="yes"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                label="Peut-être"
                                value="maybe"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                label="Absant"
                                value="no"
                                control={<Radio />}
                            />
                        </RadioGroup>
                    </FormControl>
                </CentredContent>
            </Grid>
            <Grid container spacing={1} columns={6}>
                <Grid item xs={3}>
                    <InputLabel htmlFor="firstName">Prénom</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="firstName"
                        required
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel htmlFor="lastName">Nom de famille</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="lastName"
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <OutlinedInput
                        type="email"
                        fullWidth
                        name="email"
                        required
                        startAdornment={ <InputAdornment position="start"><EmailIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel htmlFor="phone">Téléphone</InputLabel>
                    <OutlinedInput
                        type="tel"
                        fullWidth
                        name="phone"
                        required
                        startAdornment={ <InputAdornment position="start"><PhoneAndroidIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="postcode">Code postal</InputLabel>
                    <PostCodeInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="city">Ville</InputLabel>
                    <CityInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="number">N° de voie</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="number"
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="street">Voie</InputLabel>
                    <StreetInput address={address} setAddress={setAddress}/>
                </Grid>
            </Grid>
            <Divider sx={{margin: '1em'}} variant="middle" />
            <Button fullWidth type="submit" variant="contained">Mettre à jour</Button>
        </form>
    );
}

export default function NewInvitationPage() {
    const { id }  = useParams();

    return (
        <div className="event page">
            <NewInvitationForm id={ id } />
        </div>
    );
}
