import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { useNotificationsContext } from "../Contexts/Notifications";

export default function Notifications(){
      const {notifications, closeNotification } = useNotificationsContext();
      return (
        <Stack sx={{ width: '100%' }} spacing={1}>
            {notifications.map( (notification, index) => {
                return(
                    <Alert
                        key={index}
                        severity={notification.type}
                        action={ <FontAwesomeIcon icon={ faXmark } onClick={ () => closeNotification(index) } /> }>
                            {notification.content}
                    </Alert>
                );
            })}
        </Stack>
      );
}
