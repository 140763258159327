import { createContext, useContext } from "react";

import errorCode from '../Confs/errorCodes'
import apiConf from '../Confs/api'
import { useUserContext } from "./User";
import { useNotificationsContext } from "./Notifications";
import {useNavigate} from "react-router-dom";

export const RequestContext = createContext({ sendRequest: () => {} });

export const RequestContextProvider = ({ children }) => {
    const navigate = useNavigate()
    const { addNotification, closeAllNotification } = useNotificationsContext()
    const { user , setUser } = useUserContext()

    const sendRequest = ({
        endpoint,
        method= "GET",
        setter=() => {},
        onSuccess=() => {},
        onError=() => {},
        data=null,
    }) => {
        let request = {
            method: method,
            headers: {'Content-Type': 'application/json'}
        }

        if (user !== null && user.hasOwnProperty('apiKey')) {
            request['headers']['Authorization'] = 'Bearer ' + user.apiKey
        }

        if (data !== null) {
            request['body'] = JSON.stringify(data)
        }
        fetch(apiConf.url + endpoint, request)
            .then((response) => response.json())
            .then((responseData => {
                closeAllNotification()
                 if (responseData.hasOwnProperty('result') && responseData.result === 'error'){
                    if (responseData.hasOwnProperty('code')){
                        if (errorCode.hasOwnProperty(responseData.code)){
                            if (errorCode[responseData.code].hasOwnProperty('callback')) {
                                console.log('lol');
                                errorCode[responseData.code].callback(user , setUser);
                            }
                            if (errorCode[responseData.code].hasOwnProperty('msg')){
                                addNotification('warning', errorCode[responseData.code].msg);
                            }
                            if (errorCode[responseData.code].hasOwnProperty('redirect')) {
                                navigate(errorCode[responseData.code].redirect);
                            }
                        }else{
                            addNotification('error', responseData.code);
                        }
                    }
                    onError(responseData);
                }else{
                    setter(responseData);
                    onSuccess(responseData);
                }
            }))
    }
    const context = { sendRequest }
    return <RequestContext.Provider value={context}>{ children }</RequestContext.Provider>
}

export function useRequestContext(){
    return useContext(RequestContext)
}