import OutlinedInput from "@mui/material/OutlinedInput";

export default function PostCodeInput({ address, setAddress })
{
    const updateAddress = (e) => {
        setAddress({...address, postcode: e.target.value})
        if (e.target.value.length === 5) {
            fetch('https://geo.api.gouv.fr/communes?codePostal=' + e.target.value)
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.length > 0 && responseData[0].hasOwnProperty('nom')) {
                        setAddress({...address, city: responseData[0].nom, postcode: e.target.value})
                    }
                })
        }
    }

    return(
        <OutlinedInput
            type="number"
            fullWidth
            name="postcode"
            pattern="[0-9]{5}"
            min="10000"
            max="99999"
            value={ (address && address.postcode) || "" }
            required
            onChange={ updateAddress }
        />
    )
}