import { useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import KeyIcon from '@mui/icons-material/Key';

import CityInput from "../../Components/Inputs/City";
import StreetInput from "../../Components/Inputs/Street";
import PostCodeInput from "../../Components/Inputs/PostCode";
import LoadingSpinner from "../../Components/LoadingSpinner"
import { useUserContext } from "../../Contexts/User";
import { useRequestContext } from "../../Contexts/Request";
import { useNotificationsContext } from "../../Contexts/Notifications";



function ProfilForm(){
    const { sendRequest } = useRequestContext()
    const { user, setUser } = useUserContext()
    const [address, setAddress] = useState(null);
    const { addNotification } = useNotificationsContext()

    // fetch address at begin
    if (address === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Addresses/' + user.address,
            setter: setAddress
        })
        return <LoadingSpinner/>
    }
    const sendUpdateProfilRequests = (e) => {
        e.preventDefault();
        sendRequest({
            endpoint: '/Users/' + user.id,
            method: 'PUT',
            data: {
                firstName: e.target.elements.firstName.value,
                lastName: e.target.elements.lastName.value,
                phone: e.target.elements.phone.value,
                email: e.target.elements.email.value,
            },
            setter: setUser,
            onSuccess: () => {
                sendRequest({
                    method: 'PUT',
                    endpoint: '/Addresses/' + user.address,
                    data: {
                        postcode: e.target.elements.postcode.value,
                        city: e.target.elements.city.value,
                        number: e.target.elements.number.value,
                        street: e.target.elements.street.value,
                    },
                    setter: setAddress,
                    onSuccess: () => {
                        addNotification('success', 'Vos informations ont bien été mis à jour')
                    },
                })

            }
        });
    }

    return (
        <form onSubmit={ sendUpdateProfilRequests }>
            <Grid container spacing={1} columns={6}>
                <Grid item xs={3}>
                    <InputLabel htmlFor="firstName">Prénom</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="firstName"
                        defaultValue={ user.firstName }
                        required
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel htmlFor="lastName">Nom de famille</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="lastName"
                        defaultValue={ user.lastName }
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <OutlinedInput
                        type="email"
                        fullWidth
                        name="email"
                        defaultValue={ user.email }
                        required
                        startAdornment={ <InputAdornment position="start"><EmailIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel htmlFor="phone">Téléphone</InputLabel>
                    <OutlinedInput
                        type="tel"
                        fullWidth
                        name="phone"
                        defaultValue={ user.phone }
                        required
                        startAdornment={ <InputAdornment position="start"><PhoneAndroidIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="postcode">Code postal</InputLabel>
                    <PostCodeInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="city">Ville</InputLabel>
                    <CityInput address={address} setAddress={setAddress}/>
                </Grid>

                <Grid item xs={2}>
                    <InputLabel htmlFor="number">N° de voie</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="number"
                        defaultValue={ address.number }
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="street">Voie</InputLabel>
                    <StreetInput address={address} setAddress={setAddress}/>
                </Grid>
            </Grid>
            <Divider sx={{margin: '1em'}} variant="middle" />
            <Button fullWidth type="submit" variant="contained">Mettre à jour</Button>
        </form>
    )
}


function UpdatePasswordForm(){
    const { user } = useUserContext();
    const { sendRequest } = useRequestContext()
    const { addNotification } = useNotificationsContext()
    const updatePasswordRequest = (e) => {
        e.preventDefault();
        if (e.target.elements.newPassword.value === e.target.elements.confirmPassword.value){
            sendRequest({
                endpoint: '/Users/updatePassword/' + user.id,
                method: 'PUT',
                data: {
                    email: user.email,
                    password: e.target.elements.password.value,
                    newPassword: e.target.elements.newPassword.value,
                },
                onSuccess: () => {addNotification('success', 'Votre mot de passe a été mis à jour.')},
            });
        }else{
            addNotification('error', 'Les mots de passe que vous avez saisis ne correspondent pas.')
        }
    }
    return(
        <form onSubmit={updatePasswordRequest}>
            <input
                hidden
                type="email"
                name="email"
                readOnly
                value={user.email}
                autoComplete="username"
            />
            <Grid container spacing={1} columns={2}>
                <Grid item xs={2}>
                    <InputLabel htmlFor="password">Mot de passe</InputLabel>
                    <OutlinedInput
                        required
                        fullWidth
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        startAdornment={ <InputAdornment position="start"><KeyIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={1}>
                    <InputLabel htmlFor="password">Nouveau mot de passe</InputLabel>
                    <OutlinedInput
                        required
                        fullWidth
                        autoComplete="new-password"
                        type="password"
                        name="newPassword"
                    />
                </Grid>
                <Grid item xs={1}>
                    <InputLabel htmlFor="confirmPassword">Confirmation</InputLabel>
                    <OutlinedInput
                        required
                        fullWidth
                        autoComplete="new-password"
                        type="password"
                        name="confirmPassword"
                    />
                </Grid>
            </Grid>
            <Divider sx={{margin: '1em'}} variant="middle" />
            <Button fullWidth type="submit" variant="contained">Mettre à jour votre mot de passe</Button>
        </form>
    )
}

export default function SettingsPage() {
    const [currentTab, setCurrentTab] = useState('profil');

    return (
        <div className="profil page">
            <h1>Réglages</h1>
            <Box sx={{ width: '100%' , marginBottom: '33px'}}>
              <Tabs value={currentTab} onChange={(e, value) => {setCurrentTab(value)}}>
                  <Tab value="profil" label={ <AccountCircleIcon/> } />
                  <Tab value="password" label={ <LockOpenIcon />} />
              </Tabs>
            </Box>
            <Divider sx={{margin: '1em'}} variant="middle" />
            {currentTab === "profil" && <ProfilForm />}
            {currentTab === "password" && <UpdatePasswordForm />}
        </div>
    );
}