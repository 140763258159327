import { createContext,  useContext } from "react";

import useLocalStorage from '../Hooks/useLocalStorage';
export const UserContext = createContext({ user: null,setUser: () => {} });

export function UserContextProvider({ children }){
    const [ user, setUser ] = useLocalStorage('user')
    const context = { user, setUser }
    return <UserContext.Provider value={context}>{ children }</UserContext.Provider>
}

export function useUserContext(){
    return useContext(UserContext)
}