import React, { useRef, useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import conf from "../../Confs/api";
import LoadingSpinner from "../../Components/LoadingSpinner";
import {useRequestContext} from "../../Contexts/Request";
import {useParams} from "react-router-dom";
import {useUserContext} from "../../Contexts/User";

mapboxgl.accessToken = conf.mapbox.token;

function Journey({ from, to }) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-70.9);
    const [lat, setLat] = useState(42.35);
    const [zoom, setZoom] = useState(9);
    const [distance, setDistance] = useState(null);
    const [duration, setDuration] = useState(null);

    useEffect(() => {
        if (map.current) return; // initialize map only once

        const fromCoords = JSON.parse(from.latlng);
        const toCoords = JSON.parse(to.latlng);

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [fromCoords.lat, fromCoords.lng],
            zoom: zoom
        });

        // Fetch route and add to map
        const fetchRoute = async () => {
            const query = await fetch(
                `https://api.mapbox.com/directions/v5/mapbox/driving/${fromCoords.lat},${fromCoords.lng};${toCoords.lat},${toCoords.lng}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`
            );
            const data = await query.json();
            const route = data.routes[0].geometry.coordinates;
            const routeDistance = data.routes[0].distance;
            const routeDuration = data.routes[0].duration;

            // Convert distance to kilometers and duration to minutes
            setDistance((routeDistance / 1000).toFixed(2));
            setDuration((routeDuration / 60).toFixed(2));

            // Add the route as a new layer on the map
            map.current.on('load', () => {
                map.current.addSource('route', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: route
                        }
                    }
                });
                map.current.addLayer({
                    id: 'route',
                    type: 'line',
                    source: 'route',
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    paint: {
                        'line-color': '#3887be',
                        'line-width': 5,
                        'line-opacity': 0.75
                    }
                });

                // Adjust the map to fit the route
                const bounds = route.reduce((bounds, coord) => {
                    return bounds.extend(coord);
                }, new mapboxgl.LngLatBounds(route[0], route[0]));

                map.current.fitBounds(bounds, {
                    padding: 20
                });
            });
        };

        fetchRoute();

    }, [from, to, zoom]);

    return (
        <>
            <div className="map-container-wrapper" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div ref={mapContainer} className="map-container" style={{ height: '60vh', width: '100%' }} />
            </div>
            <div>
                {distance && duration && (
                    <div className="route-info" style={{ marginTop: '10px', textAlign: 'center' }}>
                        <p>Distance: {distance} km</p>
                        <p>Duration: {duration} minutes</p>
                    </div>
                )}
            </div>
        </>
    );
}

export default function UserEventJourneyPage() {
    const { user } = useUserContext();
    const { sendRequest } = useRequestContext();
    const { id } = useParams();
    const [event, setEvent] = useState(null);
    const [eventAddress, setEventAddress] = useState(null);
    const [userAddress, setUserAddress] = useState(null);

    useEffect(() => {
        if (!event) {
            sendRequest({
                method: 'GET',
                endpoint: '/Events/' + id,
                setter: setEvent
            });
        }
    }, [event, id, sendRequest]);

    useEffect(() => {
        if (event && !eventAddress) {
            sendRequest({
                method: 'GET',
                endpoint: '/Addresses/' + event.address,
                setter: setEventAddress
            });
        }
    }, [event, eventAddress, sendRequest]);

    useEffect(() => {
        if (!userAddress) {
            sendRequest({
                method: 'GET',
                endpoint: '/Addresses/' + user.address,
                setter: setUserAddress
            });
        }
    }, [user.address, userAddress, sendRequest]);

    if (!event || !eventAddress || !userAddress) {
        return <LoadingSpinner />;
    }

    return (
        <div className="event page">
            <Journey from={userAddress} to={eventAddress} />
        </div>
    );
}
