
import { Routes, Route } from "react-router-dom";
import Paper from '@mui/material/Paper';
import './App.css';

import BackButton from './Components/BackButton';
import Navbar from "./Components/Navbar";
import Notifications from "./Components/Notifications"

import { useUserContext } from "./Contexts/User";
import ListEventsPage from "./Pages/Events/List";
import EditEventsPage from "./Pages/Events/Admin";
import LoginPage from "./Pages/Users/Login";
import SettingsPage from "./Pages/Users/Settings";
import HouseholdRegisterPage from './Pages/Households/HouseholdRegister';
import RegisterPage from "./Pages/Users/Register";
import PasswordResetPage from "./Pages/Users/PasswordReset";
import NewHouseholdPage from "./Pages/Households/New";
import EditInvitationPage from "./Pages/Invitations/Edit";
import NewInvitationPage from "./Pages/Invitations/New";
import NewAccomodationOptionPage from "./Pages/AccomodationOptions/New";
import EditAccomodationOptionPage from "./Pages/AccomodationOptions/Edit";
import UserEventAccomodationOptionPage from "./Pages/AccomodationOptions/UserEventAccommodationsOptions";
import UserEventJourneyPage from "./Pages/Journey/UserEventJourney"

function PublicRoutes(){
    return (
        <Routes>
            <Route path="*" element={ <LoginPage/> }/>
            <Route path="/login" element={ <LoginPage/> }/>
            <Route path="/household-register/:householdToken" element={ <HouseholdRegisterPage/> }/>
            <Route path="/register/:token" element={ <RegisterPage/> }/>
            <Route path="/password-reset" element={ <PasswordResetPage/> }/>
            <Route path="/password-reset/:token" element={ <PasswordResetPage/>}/>
        </Routes>
    );
}

function PrivateRoutes(){
    return (
        <Routes>
            <Route path="*" element={ <ListEventsPage /> }/>
            <Route path="/profil" element={ <SettingsPage/> } />
            <Route path="/events" element={ <ListEventsPage /> } />
            <Route path="/household/new/:id" element={ <NewHouseholdPage/> } />
            <Route path="/invitation/new/:id" element={ <NewInvitationPage/> } /><Route path="/events/:id" element={ <EditEventsPage/> } />
            <Route path="/invitation/new/:id" element={ <NewInvitationPage/> } />
            <Route path="/invitation/:id" element={ <EditInvitationPage/> } />
            <Route path="/events/:id/accommodationsOptions/" element={ <UserEventAccomodationOptionPage /> } />
            <Route path="/events/:id/journey/" element={ <UserEventJourneyPage /> } />
            <Route path="/accommodationOption/new/:id" element={ <NewAccomodationOptionPage /> } />
            <Route path="/accommodationOption/:id" element={ <EditAccomodationOptionPage /> } />
        </Routes>
    );
}

export default function App(){
    const { user } = useUserContext()
    const containerStyle = {
        maxWidth: '800px',
        margin: '2em auto 2em auto',
        padding: '2em'
    }

    return (
        <div className="App container-fluid">
            <BackButton/>
            <Navbar/>
            <Paper elevation={5} sx={containerStyle}>
                <Notifications/>
                { user === null && <PublicRoutes/> }
                { user !== null && <PrivateRoutes/> }
            </Paper>
        </div>
    )
}

