import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom"
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { darkTheme } from './Theme'
import { PinkTheme } from './Theme/Pink';

import './index.css';
import App from './App';
import {UserContextProvider} from "./Contexts/User"
import {RequestContextProvider} from "./Contexts/Request"
import {NotificationsContextProvider} from "./Contexts/Notifications"
import {AddressContextProvider} from "./Contexts/Address";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
      <ThemeProvider theme={PinkTheme}>
          <NotificationsContextProvider>
              <UserContextProvider>
                  <AddressContextProvider>
                      <RequestContextProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <App theme={darkTheme}/>
                          </LocalizationProvider>
                      </RequestContextProvider>
                  </AddressContextProvider>
              </UserContextProvider>
          </NotificationsContextProvider>
      </ThemeProvider>
  </BrowserRouter>
);

