import { useParams } from "react-router-dom";
import {useState} from "react";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";


import {useRequestContext} from "../../Contexts/Request";
import {useNotificationsContext} from "../../Contexts/Notifications";
import PostCodeInput from "../../Components/Inputs/PostCode";
import CityInput from "../../Components/Inputs/City";
import StreetInput from "../../Components/Inputs/Street";

import LoadingSpinner from "../../Components/LoadingSpinner";
import CentredContent from "../../Components/CentredContent";


function EventInvitationForm()
{
    const { id } = useParams();
    const { sendRequest } = useRequestContext();
    const { addNotification } = useNotificationsContext()
    const [invitation, setInvitation] = useState(null);
    const [user, setUser] = useState(null);
    const [address, setAddress] = useState(null);

    if (invitation === null){
        sendRequest({
            method: 'GET',
            endpoint: '/Invitations/' + id,
            setter: setInvitation
        });
        return <LoadingSpinner/>
    }else if (user === null){
        sendRequest({
            method: 'GET',
            endpoint: '/Users/' + invitation.user ,
            setter: setUser
        });
        return <LoadingSpinner/>
    }else if (address === null){
        sendRequest({
            method: 'GET',
            endpoint: '/Addresses/' + user.address ,
            setter: setAddress
        });
        return <LoadingSpinner/>
    }



    const sendUpdateInvitationRequests = (e) => {
        e.preventDefault();
        sendRequest({
            endpoint: '/Users/' + user.id,
            method: 'PUT',
            data: {
                firstName: e.target.elements.firstName.value,
                lastName: e.target.elements.lastName.value,
                phone: e.target.elements.phone.value,
                email: e.target.elements.email.value,
            },
            setter: setUser,
            onSuccess: () => {
                sendRequest({
                    method: 'PUT',
                    endpoint: '/Addresses/' + user.address,
                    data: {
                        postcode: e.target.elements.postcode.value,
                        city: e.target.elements.city.value,
                        number: e.target.elements.number.value,
                        street: e.target.elements.street.value,
                    },
                    setter: setAddress,
                    onSuccess: () => {
                        sendRequest({
                            method: 'PUT',
                            endpoint: '/Invitations/' + invitation.id,
                            data: {status: e.target.elements.status.value},
                            setter: setInvitation,
                            onSuccess: () => {
                                addNotification('success', 'Invitation mis à jour')
                            }
                        })

                    },
                })

            }
        });
    }

    return(
        <form onSubmit={ sendUpdateInvitationRequests }>
            <Grid container spacing={1} columns={6}>
                <Grid item xs={3}>
                    <InputLabel htmlFor="firstName">Prénom</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="firstName"
                        defaultValue={ user.firstName }
                        required
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel htmlFor="lastName">Nom de famille</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="lastName"
                        defaultValue={ user.lastName }
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <CentredContent>
                        <FormControl sx={{ display: 'in-line' }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={ invitation.status }
                                name="status"
                            >
                                <FormControlLabel
                                    label="Présent"
                                    value="yes"
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    label="Peut-être"
                                    value="maybe"
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    label="Absant"
                                    value="no"
                                    control={<Radio />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </CentredContent>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <OutlinedInput
                        type="email"
                        fullWidth
                        name="email"
                        defaultValue={ user.email }
                        required
                        startAdornment={ <InputAdornment position="start"><EmailIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel htmlFor="phone">Téléphone</InputLabel>
                    <OutlinedInput
                        type="tel"
                        fullWidth
                        name="phone"
                        defaultValue={ user.phone }
                        required
                        startAdornment={ <InputAdornment position="start"><PhoneAndroidIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="postcode">Code postal</InputLabel>
                    <PostCodeInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="city">Ville</InputLabel>
                    <CityInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="number">N° de voie</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="number"
                        defaultValue={ address.number }
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="street">Voie</InputLabel>
                    <StreetInput address={address} setAddress={setAddress}/>
                </Grid>
            </Grid>
            <Divider sx={{margin: '1em'}} variant="middle" />
            <Button fullWidth type="submit" variant="contained">Mettre à jour</Button>
        </form>
    );
}


export default function InvitationPage() {
    return (
        <div className="event page">
            <EventInvitationForm />
        </div>
    );
}
