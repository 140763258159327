import {useState} from "react";
import {Link} from "react-router-dom";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import {AddToCalendarButton} from "add-to-calendar-button-react";



import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Card, Divider, Fab} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from '@mui/material';
import HotelIcon from '@mui/icons-material/Hotel';
import RoundaboutLeftIcon from '@mui/icons-material/RoundaboutLeft';

import CentredContent from "../../Components/CentredContent";
import LoadingSpinner from "../../Components/LoadingSpinner";
import {useUserContext} from "../../Contexts/User";
import {useRequestContext} from "../../Contexts/Request";



function UserInvitationForm(props){
    const { sendRequest } = useRequestContext()
    const [invitation, setInvitation] = useState(null);

    if (invitation === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Invitations/Event/' +  props.event.id + '/User/' + props.user.id,
            setter: setInvitation
        })
        return <LoadingSpinner/>
    }
    const updateInvitation = (e) => {
        setInvitation({...invitation, status: e.target.value})
        sendRequest({
            method: 'PUT',
            endpoint: '/Invitations/' + invitation.id,
            data: {status: e.target.value},
            setter: setInvitation,
        })
    }



    return(
        <form onSubmit={ updateInvitation }>
            <CentredContent>
                <FormControl sx={{  display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FormLabel id="status" sx={{ textAlign: 'center', marginRight: 2 }} >
                        <h3>{props.user.firstName} {props.user.lastName} : </h3>
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="status"
                        onChange={ updateInvitation }
                    >
                        <FormControlLabel
                            label="Oui"
                            value="yes"
                            checked={invitation.status === "yes"}
                            control={<Radio />}
                        />
                        <FormControlLabel
                            label="Peut-être"
                            value="maybe"
                            checked={invitation.status === "maybe"}
                            control={<Radio />}
                        />
                        <FormControlLabel
                            label="Non"
                            value="no"
                            checked={invitation.status === "no"}
                            control={<Radio />}
                        />
                    </RadioGroup>
                </FormControl>
            </CentredContent>
        </form>
    )
}

function HouseholdInvitationForm(props) {
    const { sendRequest } = useRequestContext()
    const [users, setUsers] = useState(null);
    const { user } = useUserContext()

    if (users === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Households/' + user.household + '/Users',
            setter: setUsers
        })
        return <LoadingSpinner/>
    }

    return (
        <>
            <h2 style={{ textAlign: 'center' }}>Serez vous là ?</h2>
            { users.map( (user) => <UserInvitationForm key={user.id} user={user} event={props.event} /> ) }
        </>
    )
}

function InvitationsListElem(props) {
    const { sendRequest } = useRequestContext()
    const { user } = useUserContext()
    const [event, setEvent] = useState(null)
    const [address, setAddress] = useState(null)


    // Fetch event and event's address at begin
    if (event === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Events/' + props.invitation.event,
            setter: setEvent
        })
        return <LoadingSpinner/>
    }else if (address === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Addresses/' + event.address,
            setter: setAddress
        })
        return <LoadingSpinner/>
    }
    const dateStart = new Date(event.dateStart);
    const dateEnd = new Date(event.dateEnd);

    return(
        <Card sx={{ padding: '1em' }} >
            {user.id !== event.owner ? null :
                <Link to={"/events/" + event.id}>
                    <Fab color="primary" aria-label="edit" size="small">
                        <EditIcon  />
                    </Fab>
                </Link>
            }
            <h1 style={{ textAlign: 'center' }}>{ event.name }</h1>
            <p style={{ textAlign: 'center' }}>
                Du { format(dateStart, "d", {locale: fr}) }
                au { format(dateEnd, "do MMMM yyyy", {locale: fr}) }
            </p>
            <CentredContent>
                <AddToCalendarButton
                    label="Ajouter au calendrier"
                    options="'Apple','Google'"
                    listStyle="overlay"
                    buttonStyle="round"
                    lightMode="dark"
                    name={ event.name }
                    location={ address.number + ' ' + address.street + ' ' + address.city + ' ' + address.postcode }
                    startDate={ format(dateStart, "yyyy-MM-d") }
                    endDate={ format(dateEnd, "yyyy-MM-d") }
                    startTime={ format(dateStart, "hh:mm") }
                    endTime={ format(dateEnd, "hh:mm") }
                    timeZone="Europe/Paris"
                />
            </CentredContent>

            <Divider sx={ {margin: '1em'} } variant="middle" />

            <HouseholdInvitationForm event={event}/>

            <Divider sx={ {margin: '1em'} } variant="middle" />

            <Button variant="contained" startIcon={<HotelIcon />}>
                <Link to={"/events/" + event.id + '/accommodationsOptions'}>Hébérgements</Link>
            </Button>

            <Button variant="contained" startIcon={<RoundaboutLeftIcon />}>
                <Link to={"/events/" + event.id + '/journey'}>Itinéraire</Link>
            </Button>
            
        </Card>
    )
}


function UserInvitationsList() {
    const { sendRequest } = useRequestContext()
    const { user } = useUserContext()
    const [invitations, setInvitation] = useState(null);

    // fetch invitation at begin
    if (invitations === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Invitations/Users/' + user.id,
            setter: setInvitation
        })
        return <LoadingSpinner/>
    }
    return (
        <>
            {invitations.map( (invitation) =>
                <InvitationsListElem
                    key={invitation.id}
                    invitation={ invitation }
                />
            )}
        </>
    );
}


export default function EventsPage() {

    return (
        <div className="events page">
            <UserInvitationsList />
        </div>
    );
}