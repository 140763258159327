// Theme.js
import { createTheme } from '@mui/material/styles';

export const PinkTheme = createTheme({
    palette: {
        primary: {
            main: '#ff4081', // Rose vif
            contrastText: '#fff', // Texte blanc pour les boutons primaires
        },
        secondary: {
            main: '#f44336', // Rouge
            contrastText: '#fff', // Texte blanc pour les boutons secondaires
        },
        background: {
            default: '#f5f5f5', // Gris très clair pour le fond
            paper: '#fff', // Blanc pour les éléments comme les cartes
        },
        text: {
            primary: '#000', // Noir pour le texte principal
            secondary: '#757575', // Gris pour le texte secondaire
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif', // Police de caractères personnalisée, si vous le souhaitez
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 20, // Boutons arrondis
                    textTransform: 'none', // Texte des boutons sans majuscule
                    '&.Mui-disabled': {
                        color: '#9e9e9e', // Couleur des boutons désactivés
                    },
                },
            },
        },
    },
});
