import {useParams} from "react-router-dom";
import {useState} from "react";

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import {useRequestContext} from "../../Contexts/Request";
import PostCodeInput from "../../Components/Inputs/PostCode";
import CityInput from "../../Components/Inputs/City";
import StreetInput from "../../Components/Inputs/Street";
import {useNotificationsContext} from "../../Contexts/Notifications";


function NewAccommodationOptionForm() {
    const {sendRequest} = useRequestContext();
    const { id } = useParams();
    const [address, setAddress] = useState(null);
    const { addNotification } = useNotificationsContext()

    const sendInsertAccommodationOptionRequest = (e) => {
        e.preventDefault();
        sendRequest({
            endpoint: '/Addresses/',
            method: 'POST',
            data: {
                postcode: e.target.elements.postcode.value,
                city: e.target.elements.city.value,
                number: e.target.elements.number.value,
                street: e.target.elements.street.value,
            },
            onSuccess: (addressData) => {
                sendRequest({
                    endpoint: '/AccommodationsOptions/Events/' + id,
                    method: 'POST',
                    data: {
                        event: id,
                        address: addressData.id,
                        name: e.target.elements.name.value,
                        url: e.target.elements.url.value,
                        places: e.target.elements.places.value ?? null,
                    },
                    onSuccess: () => {
                        addNotification('success', "Option d'hébergement enregisté.")
                    }
                });
            }
        });
    }

    return (
        <form onSubmit={ sendInsertAccommodationOptionRequest }>
            <Grid container spacing={1} columns={6}>
                <Grid item xs={6}>
                    <InputLabel htmlFor="name">Titre</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="name"
                        required
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="postcode">Code postal</InputLabel>
                    <PostCodeInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="city">Ville</InputLabel>
                    <CityInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="number">N° de voie</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="number"
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="street">Voie</InputLabel>
                    <StreetInput address={address} setAddress={setAddress}/>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <InputLabel htmlFor="url">Site</InputLabel>
                <OutlinedInput
                    type="url"
                    fullWidth
                    name="url"
                />
            </Grid>
            <Grid item xs={2}>
                <InputLabel htmlFor="places">Nombre de places</InputLabel>
                <OutlinedInput
                    type="number"
                    fullWidth
                    name="places"
                />
            </Grid>
            <Divider sx={{margin: '1em'}} variant="middle"/>
            <Button fullWidth type="submit" variant="contained">Mettre à jour</Button>
        </form>
    );
}

export default function NewAccomodationOptionPage() {
    const { id }  = useParams();

    return (
        <div className="event page">
            <NewAccommodationOptionForm id={ id } />
        </div>
    );
}
