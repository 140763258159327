import {createContext, useContext, useState} from "react";

export const AddressContext = createContext({ address: null,setAddress: () => {} });

export function AddressContextProvider({ children }){
    const [ address, setAddress ] = useState(null)
    const context = { address, setAddress }
    return <AddressContext.Provider value={context}>{ children }</AddressContext.Provider>
}

export function useAddressContext(){
    return useContext(AddressContext)
}