import { Link } from "react-router-dom";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate} from "react-router-dom";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import KeyIcon from '@mui/icons-material/Key';
import MoneyIcon from '@mui/icons-material/Money';

import LoadingSpinner from "../../Components/LoadingSpinner"
import { useRequestContext } from "../../Contexts/Request";
import { useNotificationsContext } from "../../Contexts/Notifications";
import StreetInput from "../../Components/Inputs/Street";
import CityInput from "../../Components/Inputs/City";
import PostCodeInput from "../../Components/Inputs/PostCode";


function RegisterForm(){
    const navigate = useNavigate();
    const { addNotification } = useNotificationsContext()
    const { sendRequest } = useRequestContext()
    const [ user, setUser ] = useState(null)
    const [ address, setAddress ] = useState(null)
    const { token }  = useParams();

    if (user == null) {
        sendRequest({
            endpoint: '/Users/register/' + token,
            method: 'GET',
            setter: setUser
        });
        return <LoadingSpinner/>
    }

    if (address === null) {
        sendRequest({
            method: 'GET',
            endpoint: '/Addresses/' + user.address,
            user: user,
            setter: setAddress
        })
        return <LoadingSpinner/>
    }

    const sendCreateUserRequest = (e) => {
        e.preventDefault();

        if (e.target.elements.password.value === e.target.elements.confirmPassword.value){
            sendRequest({
                endpoint: '/Users/register',
                method: 'PUT',
                data: {
                    id: e.target.elements.id.value,
                    firstName: e.target.elements.firstName.value,
                    lastName: e.target.elements.lastName.value,
                    phone: e.target.elements.phone.value,
                    email: e.target.elements.email.value,
                    password: e.target.elements.password.value,
                    postcode: e.target.elements.postcode.value,
                    city: e.target.elements.city.value,
                    number: e.target.elements.number.value,
                    street: e.target.elements.street.value,
                },
                onSuccess: () => {
                    navigate('/login');
                }
            })
        }else{
            addNotification('warning', 'Les mots de passe que vous avez saisis ne correspondent pas.')
        }
    }

    return(
        <form onSubmit={ sendCreateUserRequest }>
            <input type="hidden" name="id" value={user.id} />
            <Grid container spacing={1} columns={2}>
                <Grid item xs={1}>
                    <InputLabel htmlFor="firstName">Prénom</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="firstName"
                        autoComplete="on"
                        required
                        defaultValue={ user.firstName }
                        startAdornment={ <InputAdornment position="start"><BadgeIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={1}>
                    <InputLabel htmlFor="lastName">Nom de famille</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="lastName"
                        autoComplete="on"
                        required
                        defaultValue={ user.lastName }
                        startAdornment={ <InputAdornment position="start"><BadgeIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <OutlinedInput
                        type="email"
                        fullWidth
                        name="email"
                        autoComplete="on"
                        required
                        defaultValue={ user.email }
                        startAdornment={ <InputAdornment position="start"><EmailIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="phone">Téléphone</InputLabel>
                    <OutlinedInput
                        type="tel"
                        fullWidth
                        name="phone"
                        autoComplete="on"
                        required
                        defaultValue={ user.phone }
                        startAdornment={ <InputAdornment position="start"><PhoneAndroidIcon /></InputAdornment> }
                    />
                </Grid>
            <Divider sx={{margin: '2em'}} variant="middle" />
            <Grid container spacing={1} columns={2}>
                <Grid item xs={2}>
                    <InputLabel htmlFor="postcode">Code postal</InputLabel>
                    <PostCodeInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="city">Ville</InputLabel>
                    <CityInput address={address} setAddress={setAddress}/>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="number">N° de voie</InputLabel>
                    <OutlinedInput
                        type="text"
                        fullWidth
                        name="number"
                        required
                        defaultValue={ address.number }
                        startAdornment={ <InputAdornment position="start"><MoneyIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="street">Voie</InputLabel>
                    <StreetInput address={address} setAddress={setAddress}/>
                </Grid>
            </Grid>
                <Divider sx={{margin: '2em'}} variant="middle" />
                <Grid item xs={2}>
                    <InputLabel htmlFor="password">Mot de passe</InputLabel>
                    <OutlinedInput
                        type="password"
                        fullWidth
                        name="password"
                        autoComplete="new-password"
                        required
                        startAdornment={ <InputAdornment position="start"><KeyIcon /></InputAdornment> }
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel htmlFor="confirmPassword">Confirmation</InputLabel>
                    <OutlinedInput
                        type="password"
                        fullWidth
                        name="confirmPassword"
                        autoComplete="new-password"
                        required
                        startAdornment={ <InputAdornment position="start"><KeyIcon /></InputAdornment> }
                    />
                </Grid>
            </Grid>
            <Divider sx={{margin: '1em'}} variant="middle" />
            <Button fullWidth type="submit" variant="contained">Inscription</Button>
        </form>
    )
}


export default function RegisterPage() {
    return (
        <div className="register page">
            <h1>Register</h1>
            <Divider sx={{margin: '1em'}} variant="middle" />
            <RegisterForm />
            <Divider sx={{margin: '0.5em'}} variant="middle" />
            <Grid container spacing={3} columns={2} sx={{textAlign: 'center'}}>
                <Grid item xs={1}>
                    <Link to="/password-reset">
                        Mot de passe oublié
                    </Link>
                </Grid>
                <Grid item xs={1}>
                    <Link to="/login">
                        Connection
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
}