import { Link } from "react-router-dom";
import {useNavigate, useParams} from "react-router-dom";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';

import { useNotificationsContext } from "../../Contexts/Notifications";
import { useRequestContext } from "../../Contexts/Request";

function PasswordResetForm() {
    const {token}  = useParams();

    if (token && token.length){
        return (<PasswordResetStepTwo token={token}/>)
    }
    return (<PasswordResetStepOne/>)
}

function PasswordResetStepOne(){
    const {addNotification} = useNotificationsContext()
    const { sendRequest } = useRequestContext()

    const sendResetLinkRequest = (e, email) => {
        e.preventDefault();
        sendRequest({
            endpoint: '/PasswordReset/sendResetLink',
            method: 'POST',
            data: {email: email},
            onSuccess: () => { addNotification('success','Un lien vous a été envoyé par mail') },
        });
    }
    return (
        <form onSubmit={(e) => sendResetLinkRequest(e, e.target.elements.email.value)}>
            <InputLabel htmlFor="email">Adresse mail</InputLabel>
            <OutlinedInput
                fullWidth
                type="email"
                name="email"
                autoComplete="true"
                required
                startAdornment={ <InputAdornment position="start"><EmailIcon /></InputAdornment> }
            />
            <Divider sx={{margin: '1em'}} variant="middle" />
            <Button fullWidth type="submit" variant="contained">Valider</Button>
        </form>
    );
}

function PasswordResetStepTwo(props){
    const navigate = useNavigate();
    const { addNotification } = useNotificationsContext()
    const { sendRequest } = useRequestContext()

    const updatePasswordRequest = (e) => {
        e.preventDefault();
        if (e.target.elements.password.value === e.target.elements.confirmPassword.value){
            sendRequest({
                endpoint: '/PasswordReset/updatePassword',
                method: 'POST',
                data: {
                    password: e.target.elements.password.value,
                    token: props.token
                },
                onSuccess: () => {
                    addNotification('success', 'Votre mot de passe a été mis à jour.');
                    navigate('/login')
                },
            });
        }else{
            addNotification('error', 'Les mots de passe que vous avez saisis ne correspondent pas.')
        }
    }
    return (
        <form onSubmit={updatePasswordRequest}>
            <Grid container spacing={1} columns={2} sx={{textAlign: 'center'}}>
                <Grid item xs={1}>
                    <InputLabel htmlFor="password">Nouveau mot de passe</InputLabel>
                    <OutlinedInput required fullWidth type="password" name="password" />
                </Grid>
                <Grid item xs={1}>
                    <InputLabel htmlFor="confirmPassword">Confirmation</InputLabel>
                    <OutlinedInput required fullWidth type="password" name="confirmPassword" />
                </Grid>
            </Grid>
            <Divider sx={{margin: '1em'}} variant="middle" />
            <Button fullWidth type="submit" variant="contained">Mettre à jour votre mot de passe</Button>
        </form>
    );
}

export default function PasswordResetPage() {
    return (
        <div className="PasswordReset">
            <h1>Mot de passe oublié</h1>
            <Divider sx={{margin: '1em'}} variant="middle" />
            <PasswordResetForm />
            <Divider sx={{margin: '0.5em'}} variant="middle" />
            <Grid container spacing={3} columns={2} sx={{textAlign: 'center'}}>
                <Grid item xs={1}>
                    <Link to="/login">
                        Connection
                    </Link>
                </Grid>
                <Grid item xs={1}>
                    <Link to="/register">
                        Inscription
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
}